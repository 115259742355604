<template>
  <div class="dashboard">
    <v-container v-if="this.$store.state.user.user">
      <v-row>
        <v-col cols="12" md="4"  v-for="item in validItemsMenu" :key="item.text">
          <v-card class="mx-auto" outlined>
            <v-list-item class="pa-5">
              <v-list-item-avatar>
                <v-icon class="secondary lighten-1" dark>
                  {{ item.icon }}
                </v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title class="text-left headline mb-1">
                  {{ item.text }}
                </v-list-item-title>
              </v-list-item-content>

              <v-list-item-avatar size="70">
                <span class="primary--text headline font-weight-bold">{{
                  item.total
                }}</span>
              </v-list-item-avatar>
            </v-list-item>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text right color="secondary" :to="item.to">
                ver más
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { SET_DASHBOARD } from "@/store/dashboard";
export default {
  name: "Dashboard",
  data: () => ({
    dashboard: {},
    items: [
      {
        text: "Alquileres",
        to: "/loans",
        icon: "mdi-tshirt-crew",
        roles: ["*"],
        total: 0,
      },
      {
        text: "Ventas",
        to: "/sales",
        icon: "mdi-cash-register",
        roles: ["*"],
        total: 0,
      },
      {
        text: "Arreglos",
        to: "/arrangements",
        icon: "mdi-content-cut",
        roles: ["*"],
        total: 0,
      },
      {
        text: "Pagos",
        to: "/payments",
        icon: "mdi-account-cash",
        roles: ["*"],
        total: 0,
      },
      {
        text: "Categorias",
        to: "/categories",
        icon: "mdi-tag-multiple",
        roles: ["*"],
        total: 0,
      },
      {
        text: "Productos",
        to: "/dresses",
        icon: "mdi-hanger",
        roles: ["*"],
        total: 0,
      },
      {
        text: "Usuarios",
        to: "/users",
        icon: "mdi-account-group",
        roles: ["SUPERADMIN"],
        total: 0,
      },
      {
        text: "Sedes",
        to: "/offices",
        icon: "mdi-office-building-marker",
        roles: ["SUPERADMIN"],
        total: 0,
      },
      {
        text: "Empleados",
        to: "/employees",
        icon: "mdi-account-group",
        roles: ["SUPERADMIN"],
        total: 0,
      },
    ],
  }),
  created() {
    this.$store.dispatch("getDashboard");
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === SET_DASHBOARD) {
        this.dashboard = state.dashboard.dashboard;
        this.items[0].total = this.dashboard.total_loans;
        this.items[1].total = this.dashboard.total_sales;
        this.items[2].total = this.dashboard.total_arrangements;
        this.items[3].total = this.dashboard.total_payments;
        this.items[4].total = this.dashboard.total_categories;
        this.items[5].total = this.dashboard.total_dresses;
        this.items[6].total = this.dashboard.total_users;
        this.items[7].total = this.dashboard.total_offices;
        this.items[8].total = this.dashboard.total_employees;
      }
    });
  },
  computed: {
    role() {
      return this.$store.state.user.user.role;
    },
    validItemsMenu() {
      var validItems = [];
      this.items.forEach((item) => {
        if (this.validateRole(item.roles)) {
          validItems.push(item);
        }
      });
      return validItems;
    },
  },
  methods: {
    validateRole(roles) {
      if (roles.indexOf("*") >= 0) {
        return true;
      }

      if (roles.indexOf(this.role) >= 0) {
        return true;
      }

      return false;
    },
  },
};
</script>
